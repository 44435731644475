
.cardSmartPlug-1{
    border : 5px solid $warning;
    box-shadow: 1px 0px 15px 4px $warning1;
  
  }
  
  .cardSmartPlug-2{
    border : 5px solid $warning;
  }

.cardShadowBorderGreen{
    // border : 3px solid $success;
    box-shadow: 1px 0px 10px 1px $success;
}