.mobilehomeSliderControl{
    @media (max-width: 600px) {  
        visibility: hidden;       
    }
}
.homeSliderControl{
    margin-bottom: -93px;
    z-index: 2;
    background: rgba(255,255,255,0.06) !important;
    backdrop-filter: blur(10px);
    box-shadow: 0px 4px 5px $secondary;
    font-size: 1rem;
    border-radius: 0% 0% 3% 3% !important;
    .headIconHome{
        font-size: 1.5rem;
    }
    &:hover{
        background-image: -moz-linear-gradient(45deg, $success 5%,rgba(255,255,255,0.06) 40%) !important;
        background-image: 5-webkit-linear-gradient(45deg, $success 5%,rgba(255,255,255,0.06) 40%) !important;
        background-image: linear-gradient(45deg, $success 5%,rgba(255,255,255,0.06) 40%) !important;
    }
}

.pricingPackage{
    opacity:0.8;
    outline: 1px dashed $white;
    outline-offset: -10px;
    &:hover{
        opacity: 1;
    }
}

.carousel-item{
    img{
        object-fit: cover;
    }
}
