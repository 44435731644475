.btn {
    text-transform: uppercase;
}

.btn-success,
.btn-primary,
.btn-warning,
.btn-danger,
.btn-secondary {
    color: $white !important;
}


.fbButton{
    color:#4267B2;
}

.instaButton{
    color: #E1306C;
}

.twitterButton{
    color: #1DA1F2;
}